<template>
  <div>
    <div class="header_message">
      <span>更新周期：2小时</span
      ><span class="updata_times">上次更新：{{ updataTime }}</span>
    </div>
    <a-table
      :columns="columns"
      :data-source="listData"
      :pagination="false"
      :rowKey="(record) => record.id"
      :loading="loading"
    >
      <div slot="sort" slot-scope="text, record, index">
        {{
          (pagination.current - 1) * pagination.page_size + parseInt(index) + 1
        }}
      </div>
      <span slot="hot_level" slot-scope="hot_level">
        {{ hot_level | changeNum }}
      </span>
      <span slot="heat_rise_value" slot-scope="heat_rise_value">
        {{ heat_rise_value | changeNum }}
      </span>
      <span slot="id" slot-scope="text, record">
        <a @click="() => goDetail(record.heat_rise_value, record.sentence)"
          >详情</a
        >
      </span>
    </a-table>
    <!-- 分页配置 -->
    <base-pagination
      :currentPage="pagination.current"
      :pageSize="pagination.page_size"
      :total="pagination.total"
      @onChange="onChange"
      @onShowSizeChange="onChange"
    >
    </base-pagination>
  </div>
</template>
<script>
// import { createNamespacedHelpers } from 'vuex'
// const { mapState, mapMutations, mapActions } = createNamespacedHelpers('hot_topic')
const columns = [
  {
    dataIndex: "sort",
    align: "center",
    title: "排名",
    scopedSlots: { customRender: "sort" },
  },
  {
    dataIndex: "sentence",
    align: "center",
    title: "热词",
  },
  {
    title: "热度值",
    dataIndex: "hot_level",
    align: "center",
    scopedSlots: { customRender: "hot_level" },
  },
  {
    title: "24小时上升",
    dataIndex: "heat_rise_value",
    align: "center",
    scopedSlots: { customRender: "heat_rise_value" },
  },
  {
    title: "操作",
    align: "center",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
    width: 120,
  },
];

export default {
  data() {
    return {
      columns,
      listData: [],
      loading: false,
      pagination: {
        current: 1,
        page_size: 10,
        total: 0,
      },
      updataTime: "",
    };
  },
  computed: {},
  mounted() {
    this.getTopicList(1, 10);
  },
  methods: {
    // 获取列表数据
    getTopicList(current, page) {
      this.loading = true;
      let payload = {
        page_size: page,
        current,
      };
      this.$api.core.Polymers.topic(payload)
        .then((res) => {
          this.loading = false;
          if (res.list) {
            this.listData = res.list;
            this.pagination = res.pagination;
            this.updataTime = res.list[0].date;
          }
        })
        .catch((res) => {
          this.loading = false;
          console.log(res);
        });
    },
    // 分页事件-修改当前页码、修改当前页数
    onChange(current, pageSize) {
      this.getTopicList(current, pageSize);
    },
    goDetail(hot_level, id) {
      this.$router.push({
        name: 'polymers:topic_detail',
        query: {
          hot_level,
          id
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.header_message {
  font-size: 15px;
  font-weight: 500;
  margin: 8px 0;
  text-align: right;
}
.updata_times {
  display: inline-block;
  margin: 0 26px;
}
</style>